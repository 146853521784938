//DEPS
import { Layout, Menu, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { NavLink, useLocation, useHistory } from 'react-router-dom';
import { GetUserPermissions } from '../../contexts/permission-context';

//Assets
import VisitIcon from '../../assets/visits.svg';
import LoanIcon from '../../assets/loans.svg';
import SupportLoanIcon from '../../assets/supportLoans.svg';
import AgentIcon from '../../assets/agents.svg';
import UserIcon from '../../assets/users.svg';
import CoBorrower from '../../assets/co-borrower.svg';
import ConfigIcon from '../../assets/config.svg';
import PlanIcon from '../../assets/plans.svg';
import RateIcon from '../../assets/rates.svg';
import PartnerIcon from '../../assets/partners.svg';
import goldOrnamentIcon from '../../assets/gold.svg';
import percentIcon from '../../assets/percent.svg';
import lendersIcon from '../../assets/lenders.svg';
import goldMarkingIcon from '../../assets/Gold_Marking.svg';
import { OfferContext } from '../../App';
import ClosureRequestSvg from '../svg/ClosureRequestSvg';
import ReportsSvg from '../svg/ReportSvg';

// utils
import permissions from '../../utils/permissionConfig';
import Utils from '../../utils';
import { checkIfComponentHasAccess } from '../../utils';
import {
	isAgentSelected,
	isBannerSelected,
	isCoborrowerSelected,
	isConfigSelected,
	isGoldMarkingsSelected,
	isGoldOrnamentsSelected,
	isInsightsSelected,
	isLenderssSelected,
	isOldOffersSelected,
	isOroOfferSelected,
	isPartnerSelected,
	isPincodesSelected,
	isPlanSelected,
	isRateSelected,
	isUsersSelected,
} from './utils/helpers';
import { useNetworkStatus } from '../NetworkStatusContext';
import ConfirmationModal from '../Modals/Confirmation';
import { logout } from '../../utils/userSessions';
import { clearAllLocalStorage } from '../../utils/local-storage';
// assets
import DisbursementCenterSvg from '../svg/DC';
import DCVisitsSvg from '../svg/DCVisits';
import DCLoansSvg from '../svg/DCLoans';
import TakeoverSvg from '../svg/TakeoverSvg';
import BRLVisitSvg from '../svg/BRLVisitSvg';
import BRLLoansSvg from '../svg/BRLLoans';
import ELRVisitSvg from '../svg/ELRVisitSvg';
import TakeoverLoansSvg from '../svg/TakeoverLoans';
import TakeoverVisitSvg from '../svg/TakeoverVisitSvg';
import SalesVisitIcon from '../svg/SalesVisitsIcon';
import supabase from '../../utils/supabase';
import PaisaIconSvg from '../svg/PaisaIcon';
import OfferIconSvg from '../svg/OfferIconSvg';
import ClosureRequestIconSvg from '../svg/ClosureRequestIconSvg';
import UsersIconSidebar from '../svg/UserIconSidebar';
import { deleteAllCookies, getValueFromCookie } from '../../utils/cookies';
import ArrowLeftSidebar from '../svg/ArrowLeftSidebar';
import LogoutIconSvg from '../svg/LogoutIconSvg';
import UsersSvg from '../svg/UsersSvg';
import SettingsSvgIcon from '../svg/SettingsSvgIcon';
import { getPaisaRoute } from './utils/constants';
import DcWalkInSvg from '../svg/DcWalkInSvg';

const { SubMenu } = Menu;
const { Sider } = Layout;

const ADMIN_TAKEOVER_BASE_URL = 'http://localhost:3000';

const AdminSidebar = (props) => {
	const location = useLocation();
	const history = useHistory();
	const activeLinks = location?.pathname?.split('/') || [];
	const PRIMARY_GRAY_COLOR = '#909090';
	const PRIMARY_YELLOW_COLOR = '#FFC351';
	const [version, setVersion] = React.useState('0.0.0');

	const { permissions: userPermissions } = GetUserPermissions();

	const [openSidebar, setOpenSidebar] = useState(true);
	const [renderSidebarContent, setRenderSidebarContent] = useState(true);
	const [confirmationModalVisible, setConfirmationModalVisible] =
		useState(false);

	const { isOnline } = useNetworkStatus();

	const [state, setStateHelper] = useState({
		pendingOfferCount: null,
	});
	let user = getValueFromCookie('user');
	const setState = (changes) => {
		setStateHelper((prevState) => ({
			...prevState,
			...changes,
		}));
	};

	const defaultSubMenuOpen = () => {
		let key = window.location.pathname.split('/')[1];
		return ['config'].includes(key);
	};

	const offerData = React.useContext(OfferContext);
	useEffect(() => {
		let timeout;
		if (openSidebar) {
			timeout = setTimeout(() => {
				setRenderSidebarContent(true);
			}, 300);
		} else {
			setRenderSidebarContent(false);
		}
		return () => clearTimeout(timeout);
	}, [openSidebar]);
	useEffect(() => {
		const x = offerData?.getPendingOfferCount();
	}, []);

	useEffect(() => {
		async function getVersion() {
			const { data } = await supabase.from('versions').select('*').single();
			setVersion(Utils.sumVersions(data?.admin_v1, data?.admin_v2));
		}
		getVersion();
	}, []);
	return (
		<Sider
			width={openSidebar ? 271 : 80}
			className='pt-6 bg-white border-r relative'
		>
			<div
				role='presentation'
				className='fixed border border-label h-6 w-6 rounded-md flex items-center justify-center cursor-pointer bg-white '
				onClick={(e) => {
					e.stopPropagation()
					setOpenSidebar(!openSidebar)
				}
				}
				style={{ left: openSidebar ? "256px" : "68px" }}
			>
				<div style={!openSidebar ? { transform: 'rotate(180deg)' } : {}}>
					<ArrowLeftSidebar />
				</div>
			</div>
			
			<div style={{ marginLeft: '-10px', height: "100vh", overflowY : "auto", paddingBottom: "50px", marginTop : "20px" }}>
				<Menu
					mode='inline'
					selectedKeys={[
						window.location.pathname.split('/')[1], // for /loans urls
						window.location.pathname.split('/')[2], // for /config/ urls
					]}
					defaultSelectedKeys={['visits']}
					defaultOpenKeys={defaultSubMenuOpen() ? ['config'] : []}
				>
					{checkIfComponentHasAccess(
						userPermissions,
						permissions.visits.permission,
						false
					) ? (
						<a href={`/visits`}>
							{renderSidebarContent ? (
								<SubMenu
									key='visits'
									title={
										<span className='font-bold text-base pl-3 text-label'>
											Visits
										</span>
									}
									icon={<img src={VisitIcon} alt='' />}
									className='ml-5'
								>
									<Menu.Item key='visits'>
										<a href={`/visits`}>
											<div className='text-body-bold flex space-x-2'>
												<span className=''>Visits</span>
											</div>
										</a>
									</Menu.Item>
								</SubMenu>
							) : (
								<img className='ml-8 mt-4' src={VisitIcon} alt='' />
							)}
						</a>
					) : null}

					{checkIfComponentHasAccess(
						userPermissions,
						permissions.loans.permission,
						false
					) ? (
						<a href={`/loans`}>
							{renderSidebarContent ? (
								<SubMenu
									key='loans'
									title={
										<span className='font-bold text-base ml-5 text-label'>
											Loans
										</span>
									}
									icon={<ELRVisitSvg />}
									className='ml-5'
								>
									<Menu.Item key='loans'>
										<a href={`/loans`}>
											<div className='flex space-x-2 text-body-bold'>
												<span>Loans</span>
											</div>
										</a>
									</Menu.Item>{' '}
								</SubMenu>
							) : (
								<div className='ml-8 mt-6'>
									{' '}
									<ELRVisitSvg />
								</div>
							)}
						</a>
					) : null}

					{checkIfComponentHasAccess(
						userPermissions,
						permissions.config.permission,
						true
					) ? (
						<>
							{renderSidebarContent ? (
								<SubMenu
									key='config'
									icon={
										<span>
											<SettingsSvgIcon
												width='20'
												height='20'
												stroke={
													isConfigSelected(activeLinks)
														? PRIMARY_YELLOW_COLOR
														: PRIMARY_GRAY_COLOR
												}
											/>
										</span>
									}
									title={
										<span
											className={`font-bold text-base pl-3 ${
												isConfigSelected(activeLinks)
													? 'text-black'
													: 'text-label'
											}`}
										>
											Config
										</span>
									}
									className='submenuBar'
								>
									{checkIfComponentHasAccess(
										userPermissions,
										permissions.config.plans.permission,
										false
									) ? (
										<Menu.Item key='plans'>
											<NavLink
												to='/config/plans'
												className={`${
													!isPlanSelected(activeLinks) ? 'hover-change' : ''
												}`}
											>
												<div className='flex space-x-2 ml-5'>
													<span
														className={`font-bold text-base ${
															isPlanSelected(activeLinks)
																? 'text-black'
																: 'text-label'
														}`}
													>
														Plans
													</span>
												</div>
											</NavLink>
										</Menu.Item>
									) : null}
									{checkIfComponentHasAccess(
										userPermissions,
										permissions.config.rates.permission,
										false
									) ? (
										<Menu.Item key='rates'>
											<NavLink
												to='/config/rates'
												className={`${
													!isRateSelected(activeLinks) ? 'hover-change' : ''
												}`}
											>
												<div className='flex space-x-2 ml-5'>
													<span
														className={`font-bold text-base ${
															isRateSelected(activeLinks)
																? 'text-black'
																: 'text-label'
														}`}
													>
														Rates
													</span>
												</div>
											</NavLink>
										</Menu.Item>
									) : null}
									{checkIfComponentHasAccess(
										userPermissions,
										permissions.config.partners.permission,
										false
									) ? (
										<Menu.Item key='partners'>
											<NavLink
												to='/config/partners'
												className={`${
													!isPartnerSelected(activeLinks) ? 'hover-change' : ''
												}`}
											>
												<div className='flex space-x-2 ml-5'>
													<span
														className={`font-bold text-base ${
															isPartnerSelected(activeLinks)
																? 'text-black'
																: 'text-label'
														}`}
													>
														Partners
													</span>
												</div>
											</NavLink>
										</Menu.Item>
									) : null}
									{checkIfComponentHasAccess(
										userPermissions,
										permissions.config.gold_ornaments.permission,
										false
									) ? (
										<Menu.Item key='manage-gold-ornament-types'>
											<NavLink
												to='/config/manage-gold-ornament-types'
												className={`${
													!isGoldOrnamentsSelected(activeLinks)
														? 'hover-change'
														: ''
												}`}
											>
												<div className='flex space-x-2 ml-5'>
													<span
														className={`font-bold text-base ${
															isGoldOrnamentsSelected(activeLinks)
																? 'text-black'
																: 'text-label'
														}`}
													>
														Gold Ornaments
													</span>
												</div>
											</NavLink>
										</Menu.Item>
									) : null}
									{checkIfComponentHasAccess(
										userPermissions,
										permissions.config.pin_codes.permission,
										false
									) ? (
										<Menu.Item key='manage-pincodes'>
											<NavLink
												to='/config/manage-pincodes'
												className={`${
													!isPincodesSelected(activeLinks) ? 'hover-change' : ''
												}`}
											>
												<div className='flex space-x-2 ml-5'>
													<span
														className={`font-bold text-base ${
															isPincodesSelected(activeLinks)
																? 'text-black'
																: 'text-label'
														}`}
													>
														Pin Codes
													</span>
												</div>
											</NavLink>
										</Menu.Item>
									) : null}
									{checkIfComponentHasAccess(
										userPermissions,
										permissions.config.lenders.permission,
										false
									) ? (
										<Menu.Item key='lenders'>
											<NavLink
												to='/config/lenders'
												className={`${
													!isLenderssSelected(activeLinks) ? 'hover-change' : ''
												}`}
											>
												<div className='flex space-x-2 ml-5'>
													<span
														className={`font-bold text-base ${
															isLenderssSelected(activeLinks)
																? 'text-black'
																: 'text-label'
														}`}
													>
														Lenders
													</span>
												</div>
											</NavLink>
										</Menu.Item>
									) : null}
									{checkIfComponentHasAccess(
										userPermissions,
										permissions.config.gold_marking.permission,
										false
									) ? (
										<Menu.Item key='gold-marking-types'>
											<NavLink
												to='/config/gold-marking-types'
												className={`${
													!isGoldMarkingsSelected(activeLinks)
														? 'hover-change'
														: ''
												}`}
											>
												<div className='flex space-x-2 ml-5'>
													<span
														className={`font-bold text-base ${
															isGoldMarkingsSelected(activeLinks)
																? 'text-black'
																: 'text-label'
														}`}
													>
														Gold Marking
													</span>
												</div>
											</NavLink>
										</Menu.Item>
									) : null}
									{checkIfComponentHasAccess(
										userPermissions,
										permissions.config.old_offers.view_offer_list.permission,
										false
									) ? (
										<Menu.Item key='old-offer'>
											<NavLink
												to='/config/old-offer'
												className={`${
													!isOldOffersSelected(activeLinks)
														? 'hover-change'
														: ''
												}`}
											>
												<div className='flex space-x-2 ml-5'>
													<span
														className={`font-bold text-base ${
															isOldOffersSelected(activeLinks)
																? 'text-black'
																: 'text-label'
														}`}
													>
														Old Offers
													</span>
												</div>
											</NavLink>
										</Menu.Item>
									) : null}
									{checkIfComponentHasAccess(
										userPermissions,
										permissions.config.banners.permission,
										false
									) ? (
										<Menu.Item key='banners'>
											<a
												href={`/config/banners`}
												className={`${
													!isBannerSelected(activeLinks) ? 'hover-change' : ''
												}`}
											>
												<div className='flex space-x-2 ml-5'>
													<span
														className={`font-bold text-base ${
															isBannerSelected(activeLinks)
																? 'text-black'
																: 'text-label'
														}`}
													>
														Banners
													</span>
												</div>
											</a>
										</Menu.Item>
									) : null}
									{checkIfComponentHasAccess(
										userPermissions,
										permissions.dc.dc_details.permission,
										false
									) ? (
										<Menu.Item key='dc-details'>
											<a
												href={`/disbursement-centre/dc-details`}
												className='hover-change'
											>
												<div className='flex space-x-2 ml-5'>
													<span className='font-bold text-base text-label'>
														DC Details
													</span>
												</div>
											</a>
										</Menu.Item>
									) : null}
									{checkIfComponentHasAccess(
										userPermissions,
										permissions.agents.permission,
										false
									) ? (
										<Menu.Item key='agents'>
											<a
												href='/agents'
												className='hover-change'
											>
												<div className='flex space-x-2 ml-5'>
													<span
														className={`font-bold text-base ${
															isAgentSelected(activeLinks)
																? 'text-black'
																: 'text-label'
														}`}
													>
														Agents
													</span>
												</div>
											</a>
										</Menu.Item>
									) : null}
									{checkIfComponentHasAccess(
										userPermissions,
										permissions.insights.permission,
										false
									) ? (
										<Menu.Item key='insights'>
											<NavLink
												to='/insights'
												className={`${
													!isInsightsSelected(activeLinks) ? 'hover-change' : ''
												}`}
											>
												<div className='flex space-x-2 ml-5'>
													<span
														className={`font-bold text-base ${
															isInsightsSelected(activeLinks)
																? 'text-black'
																: 'text-label'
														}`}
													>
														Insights
													</span>
												</div>
											</NavLink>
										</Menu.Item>
									) : null}
								</SubMenu>
							) : (
								<div
									onClick={() => setOpenSidebar(true)}
									className='mt-6 ml-8'
									src={ConfigIcon}
								>
									<SettingsSvgIcon
										width='20'
										height='20'
										stroke={
											isConfigSelected(activeLinks)
												? PRIMARY_YELLOW_COLOR
												: PRIMARY_GRAY_COLOR
										}
									/>
								</div>
							)}
						</>
					) : null}

					{checkIfComponentHasAccess(
						userPermissions,
						permissions.oro_offer.view_oro_offer.permission,
						false
					) ? (
						<>
							{renderSidebarContent ? (
								<Menu.Item key='oro-offer' className='ml-2'>
									<NavLink to='/oro-offer'>
										<div className='flex space-x-2'>
											<span>
												<OfferIconSvg
													stroke={
														isOroOfferSelected(activeLinks)
															? PRIMARY_YELLOW_COLOR
															: PRIMARY_GRAY_COLOR
													}
												/>
											</span>
											<span
												className={`font-bold text-base pl-3 ${
													isOroOfferSelected(activeLinks)
														? 'text-black'
														: 'text-label'
												}`}
											>
												Oro Offer
											</span>
											{/* <span className='h-6 w-6 text-center text-xs p-1 rounded-lg bg-red-500 text-white'>
												{offerData?.pendingCount ?? 0}
											</span> */}
										</div>
									</NavLink>
								</Menu.Item>
							) : (
								<div className=' ml-8 mt-6'>
									{' '}
									<OfferIconSvg
										stroke={
											isOroOfferSelected(activeLinks)
												? PRIMARY_YELLOW_COLOR
												: PRIMARY_GRAY_COLOR
										}
									/>
								</div>
							)}
						</>
					) : null}


                    {checkIfComponentHasAccess(
						userPermissions,
						permissions.paisa.view_paisa_sidebar_tab.permission,
						true
					) ? (
						<a href={`/dc-walk-in/all-walk-in`}>
							{renderSidebarContent ? (
								<SubMenu
									key='Dc Walk-in'
									title={
										<span className='font-bold text-base ml-2 pl-3 text-label'>
											Dc Walk-in
										</span>
									}
									icon={<DcWalkInSvg />}
									className='ml-5'
								>
									<Menu.Item key='All walk-in'>
										<a href={`/dc-walk-in/all-walk-in`}>
											<div className='flex space-x-2 text-body-bold'>
												<span className='font-bold text-base ml-2 text-label'>
													All Walk-in
												</span>
											</div>
										</a>
									</Menu.Item>
								</SubMenu>
							) : (
								<div className='ml-8 mt-6'>
									<DcWalkInSvg />
								</div>
							)}
						</a>
					) : null}

					{checkIfComponentHasAccess(
						userPermissions,
						permissions.closure_friction.permission,
						false
					) ? (
						<>
							{renderSidebarContent ? (
								<Menu.Item key='closure-requests' className='ml-2'>
									<a href={`/closure-requests`}>
										<div className='flex'>
											<ClosureRequestIconSvg />
											<span className='font-bold text-base pl-5 text-label'>
												Closure Requests
											</span>
										</div>
									</a>
								</Menu.Item>
							) : (
								<a href={`/closure-requests`}>
									<div className=' ml-8 mt-6'>
										{' '}
										<ClosureRequestIconSvg />
									</div>
								</a>
							)}
						</>
					) : null}

					{checkIfComponentHasAccess(
						userPermissions,
						permissions.reports.permission,
						false
					) ? (
						<>
							{renderSidebarContent ? (
								<Menu.Item key='reports' className='ml-2'>
									<a href='/reports'>
										<div className='flex'>
											<span>
												<ReportsSvg stroke='#909090' />
											</span>
											<span className='font-bold text-base ml-2 pl-3 text-label'>
												Reports
											</span>
										</div>
									</a>
								</Menu.Item>
							) : (
								<a href='/reports'>
									<div className='ml-8 mt-6'>
										{' '}
										<ReportsSvg stroke='#909090' />
									</div>
								</a>
							)}
						</>
					) : null}
					{checkIfComponentHasAccess(
						userPermissions,
						permissions.users.permission,
						false
					) ? (
						<>
							{renderSidebarContent ? (
								<SubMenu
									key='users'
									title={
										<span
											className={`font-bold text-base ml-2 pl-3 ${
												isUsersSelected(activeLinks)
													? 'text-black'
													: 'text-label'
											}`}
										>
											Users & KYC
										</span>
									}
									icon={
										<UsersSvg
											width='20'
											height='20'
											stroke={
												isUsersSelected(activeLinks)
													? PRIMARY_YELLOW_COLOR
													: PRIMARY_GRAY_COLOR
											}
										/>
									}
									className='submenuBar'
								>
									<Menu.Item key='users'>
										<a href={`/users`} className='hover-change'>
											<div className='flex space-x-2 text-body-bold'>
												<span className='font-bold text-base ml-2 text-label'>
													Loan Users
												</span>
											</div>
										</a>
									</Menu.Item>
									{checkIfComponentHasAccess(
										userPermissions,
										permissions.coborrowers.permission,
										false
									) ? (
										<Menu.Item key='co-borrower'>
											<NavLink
												to='/co-borrower'
												className={`${
													!isCoborrowerSelected(activeLinks)
														? 'hover-chnage'
														: ''
												}`}
											>
												<div className='flex space-x-2 text-body-bold'>
													<span
														className={`font-bold text-base ml-2 ${
															isCoborrowerSelected(activeLinks)
																? 'text-black'
																: 'text-label'
														}`}
													>
														Co-borrower
													</span>
												</div>
											</NavLink>
										</Menu.Item>
									) : null}
								</SubMenu>
							) : (
								<div
									className='ml-8 mt-6 cursor-pointer'
									onClick={() => setOpenSidebar(true)}
								>
									<UsersSvg
										width='20'
										height='20'
										stroke={
											isUsersSelected(activeLinks)
												? PRIMARY_YELLOW_COLOR
												: PRIMARY_GRAY_COLOR
										}
									/>
								</div>
							)}
						</>
					) : null}

					{checkIfComponentHasAccess(
						userPermissions,
						permissions.paisa.view_paisa_sidebar_tab.permission,
						true
					) ? (
						<a href={getPaisaRoute()}>
							{renderSidebarContent ? (
								<SubMenu
									key='Paisa'
									title={
										<span className='font-bold text-base ml-2 pl-3 text-label'>
											Paisa
										</span>
									}
									icon={<PaisaIconSvg />}
									className='ml-5'
								>
									<Menu.Item key='payments'>
										<a href={`/payments`}>
											<div className='flex space-x-2 text-body-bold'>
												<span className='font-bold text-base ml-2 text-label'>
													Customer Repayments
												</span>
											</div>
										</a>
									</Menu.Item>

									<Menu.Item key='refunds'>
										<NavLink to='/refunds'>
											<div className='flex space-x-2 text-body-bold'>
												<span className='font-bold text-base ml-2 text-label'>
													Refunds
												</span>
											</div>
										</NavLink>
									</Menu.Item>

									<Menu.Item key='fund-transfer'>
										<NavLink to='/fund-transfer'>
											<div className='flex space-x-2 text-body-bold'>
												<span className='font-bold text-base ml-2 text-label'>
													Fund Transfers
												</span>
											</div>
										</NavLink>
									</Menu.Item>

									<Menu.Item key='payouts'>
										<NavLink to='/payouts'>
											<div className='flex space-x-2 text-body-bold'>
												<span className='font-bold text-base ml-2 text-label'>
													Payouts
												</span>
											</div>
										</NavLink>
									</Menu.Item>

									<Menu.Item key='fetch-federal-closure'>
										<NavLink to='/fetch-closure-amount'>
											<div className='flex space-x-2 text-body-bold'>
												<span className='font-bold text-base ml-2 text-label'>
													Fetch Federal Closure
												</span>
											</div>
										</NavLink>
									</Menu.Item>
								</SubMenu>
							) : (
								<div className='ml-8 mt-6'>
									<PaisaIconSvg />
								</div>
							)}
						</a>
					) : null}

					<>
						{renderSidebarContent ? (
							<Menu.Item
								key='logout'
								onClick={() => setConfirmationModalVisible(true)}
							>
								<div className='flex ml-1'>
									<LogoutIconSvg />
									<p className='text-base font-bold text-error-red ml-2 pl-3'>
										Logout
									</p>
								</div>
							</Menu.Item>
						) : (
							<div
								className='ml-8 mt-6 cursor-pointer'
								onClick={() => setConfirmationModalVisible(true)}
							>
								{' '}
								<LogoutIconSvg />
							</div>
						)}
					</>
				</Menu>
			</div>

			<p className='absolute  flex items-center justify-center  font-extrabold sidebar-left text-sm h-8 px-3 py-1 border-2 border-border-grey rounded-lg bg-white text-label bottom-4 ml-6'>
				V{version}
			</p>
			<ConfirmationModal
				showCancelButton
				visible={confirmationModalVisible}
				setVisible={setConfirmationModalVisible}
				backButtonText='Cancel'
				onConfirm={async () => {
					setConfirmationModalVisible(false);

					logout().then((res) => {
						if (res.code === '200') {
							deleteAllCookies();
							clearAllLocalStorage();
							history.replace('/');
						} else {
							notification.error({
								message: 'Something went wrong',
								description: res.message,
							});
						}
					});
				}}
				text={
					<p className='w-2/3 mx-auto'>
						Warning! <br /> Are you sure you want to Logout?
					</p>
				}
				buttonText='Logout'
			/>
		</Sider>
	);
};

export default AdminSidebar;
